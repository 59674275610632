// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #bb124f;
  --ion-color-primary-rgb: 212, 20, 90;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #bb124f;
  --ion-color-primary-tint: #D4145A;

  /** secondary **/
  --ion-color-secondary: #FBB03B;
  --ion-color-secondary-rgb: 251, 176, 59;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #dd9b34;
  --ion-color-secondary-tint: #fbb84f;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-grid-width-xl: 992px;

  --ion-font-family: 'Quicksand', 'Cairo', sans-serif;

  .ion-color-fb {
    --ion-color-base: #3b5999;
    --ion-color-base-rgb: 105, 187, 123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #3b5999;
    --ion-color-tint: #3b5999;
  }

  .ion-color-twitter {
    --ion-color-base: #55acee;
    --ion-color-base-rgb: 105, 187, 123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #55acee;
    --ion-color-tint: #55acee;
  }

  .ion-color-whatsapp {
    --ion-color-base: #25D366;
    --ion-color-base-rgb: 105, 187, 123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #25D366;
    --ion-color-tint: #25D366;
  }

  .ion-color-white {
    --ion-color-base: #ffffff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #e0e0e0;
    --ion-color-tint: #ffffff;
  }

  .ion-color-transparent {
    --ion-color-base: #ffffff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #e0e0e0;
    --ion-color-tint: #ffffff;
  }

  ion-modal {
    --border-radius: 0;
  }

  ion-button {
    outline: none !important;
  }

  ion-chip {
    line-height: 1.5;
  }

  ion-refresher, ion-refresher-content {
    background: transparent;
  }

  @media (min-width: 992px) {
    ion-modal {
      --border-radius: 6px;
    }
  }


  .bold {
    font-weight: bold;
  }

  ion-tab-bar#camerascan {
    background: transparent;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}
